#map {
  $heightHeader: var(--header-height, 0);

  position: fixed;
  top: $heightHeader;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100% - #{$heightHeader});
  height: calc(100% - #{$heightHeader});

  background: var(--bs-white);

  // border: 5px solid blue;
}

@media (max-width: 991px) {
  .bg-dark {
    display: none;
  }
}

.map-container {
  overflow: hidden;
}

.maptiler-element {
  position: absolute;
  left: 20px;
  bottom: 5px;
  z-index: 999;
}

.leaflet-control-attribution a svg {
  display: none !important;
}
