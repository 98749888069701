@charset "UTF-8";
:root {
  --active-sidebar: #fff700;
}

html {
  -webkit-tap-highlight-color: transparent;
  height: -webkit-fill-available;
  min-height: 100vh;
}

html,
body {
  height: 100%;
  width: 100vw;
}

body {
  margin: 0;
}
@media (max-width: 992px) {
  body {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}
main {
  flex: 1;
}

#map {
  position: fixed;
  top: var(--header-height, 0);
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100% - var(--header-height, 0));
  height: calc(100% - var(--header-height, 0));
  background: var(--bs-white);
}

@media (max-width: 991px) {
  .bg-dark {
    display: none;
  }
}
.map-container {
  overflow: hidden;
}

.maptiler-element {
  position: absolute;
  left: 20px;
  bottom: 5px;
  z-index: 999;
}

.leaflet-control-attribution a svg {
  display: none !important;
}

.leaflet-control-container .leaflet-top,
.leaflet-control-container .leaflet-bottom {
  will-change: transform;
}

.icon {
  display: flex;
  border-radius: 50%;
  background-color: var(--bs-white);
}

.leaflet-marker-icon span {
  font-family: "Oswald", sans-serif;
}

.icon-flights {
  border: 2px solid var(--bs-white);
}

@keyframes border-pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
.icon-flights.animation {
  animation: border-pulse 1.5s infinite;
}

.icon-current {
  border: 2px solid var(--bs-white);
  animation: border-pulse 1.5s infinite;
}

.information-row {
  cursor: pointer;
  gap: 10px;
  padding: 10px;
}
.information-row .icon {
  width: 35px;
  height: 35px;
  border: 1px solid var(--bs-gray-400);
  transition: border 0.5s ease-out;
}
.information-row:hover {
  background-color: var(--bs-gray-100);
}
.information-row:hover .icon {
  box-shadow: inset 0px 0px 13px -5px rgb(139, 220, 255);
  border: 1px solid var(--bs-blue);
}
.information-row:hover .information-row-name {
  color: var(--bs-gray-900);
}

.leaflet-marker-icon:not(.leaflet-control-locate-heading) svg {
  width: 24px;
  height: 24px;
}

.information-row-icon svg {
  width: 24px;
  height: 24px;
}

.icon-accessible-toilet > * {
  border: 1px solid var(--bs-white);
}

.leaflet-marker-icon:not(.leaflet-control-locate-location) {
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.sidebar {
  position: fixed;
  bottom: 0;
  z-index: 1002;
  width: 100%;
  max-width: 500px;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: rgba(57, 57, 57, 0.8);
  line-height: 80%;
  text-align: center;
}
@media (max-width: 430px) {
  .sidebar .menu-text {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .sidebar {
    max-width: 100%;
    border-radius: 0;
    margin-bottom: 0;
    left: 0;
  }
}
.sidebar .sidebar-location {
  display: var(--has-touch-screen);
}

.menu-item {
  user-select: none;
  display: flex;
  cursor: pointer;
  padding: 10px;
  color: var(--bs-white);
  align-items: center;
  font-size: 80%;
  align-self: center;
  transition: color 0.5s ease-out;
}
@media (max-width: 430px) {
  .menu-item {
    padding: 5px;
  }
}
.menu-item svg {
  pointer-events: none;
  width: 35px;
  height: 35px;
  fill: var(--bs-white);
  transition: fill 0.5s ease-out;
}
@media (max-width: 450px) {
  .menu-item svg {
    width: 25px;
    height: 25px;
  }
}
.menu-item:hover svg {
  transition: fill 0.4s ease-in;
  fill: var(--bs-warning);
}
.menu-item:hover .menu-text {
  transition: color 0.4s ease-in;
  color: var(--bs-warning);
}

.menu-text {
  word-wrap: break-word;
}

.fullscreen-is-active {
  overflow: hidden;
}
.fullscreen-is-active .sidebar-fullscreen-exit {
  display: flex;
}
@media (max-width: 992px) {
  .fullscreen-is-active .sidebar-fullscreen-exit {
    display: none;
  }
}
.fullscreen-is-active .sidebar-fullscreen-in {
  display: none;
}
.fullscreen-is-active #map {
  position: fixed;
  width: 100%;
  border: 0;
  height: 100%;
  z-index: 9;
}
@media (max-width: 992px) {
  .fullscreen-is-active #map {
    height: calc(100vh - 50px);
  }
}
@media (max-width: 991px) {
  .fullscreen-is-active .navbar {
    z-index: 999;
  }
}
@media (min-width: 992px) {
  .fullscreen-is-active .navbar {
    z-index: 0;
  }
}
.fullscreen-is-active .sidebar {
  position: fixed;
}
.fullscreen-is-active .maptiler-element {
  position: fixed;
  left: 10px;
  bottom: 5px;
}

.location-is-active .sidebar-location svg {
  fill: var(--bs-blue);
}
.location-is-active .sidebar-location .menu-text {
  color: var(--active-sidebar);
}

.slider-title {
  position: relative;
  border-radius: 10px 10px 0 0;
  background-color: var(--main-color-bg, #393939);
  color: var(--bs-white);
  text-align: center;
  font-size: 90%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-title img {
  position: absolute;
  left: 10px;
  height: 38px;
}
@media (max-width: 600px) {
  .slider-title {
    border-radius: 0;
  }
}

.close-slider {
  position: absolute;
  top: 10px;
  right: 5px;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.close-slider .icon {
  pointer-events: none;
  background: none;
  width: 30px;
  height: 30px;
  fill: var(--bs-white);
}

.slider-content {
  overflow: auto;
  height: calc(100% - var(--sidebar-height));
}

.popup-content h3 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
}
.popup-content tbody th {
  font-weight: normal;
}
.popup-content .table > :not(caption) > * > * {
  padding: 0.1rem 0.3rem;
}
.popup-content thead > tr > :nth-child(1),
.popup-content tbody > tr > :nth-child(1) {
  padding: 0.1rem 0rem;
}
.popup-content .top-five-country span {
  width: 14px;
  height: 11px;
}

.leaflet-popup .card {
  border: 0;
}
.leaflet-popup .card-title {
  font-size: 1.3rem;
  font-weight: bold;
}
.leaflet-popup .popup-content h4 {
  font-size: 1.1rem;
  font-weight: bold;
}
.leaflet-popup table {
  margin-bottom: 0 !important;
}
.leaflet-popup .player-teetime {
  line-height: 120%;
}

.group-popup {
  font-family: "Oswald", sans-serif;
}
.group-popup .leaflet-popup-content {
  min-width: 240px !important;
  max-width: 240px !important;
}

.leaflet-popup:not(.group-popup) {
  font-family: "Roboto", sans-serif !important;
}

.sidebar-flags svg {
  fill: var(--active-sidebar);
}
.sidebar-flags .menu-text {
  color: var(--active-sidebar);
}

.flags-is-removed .sidebar-flags svg {
  fill: var(--bs-white);
}
.flags-is-removed .sidebar-flags .menu-text {
  color: var(--bs-white);
}

.favorite {
  display: none;
}

.favorite-player-on-map {
  background-color: #f7a001 !important;
}
.favorite-player-on-map svg {
  fill: var(--bs-white);
}
.favorite-player-on-map .first {
  display: none;
}
.favorite-player-on-map .favorite {
  display: block;
}

.location-button, .back-to-home {
  display: var(--has-touch-screen);
  background: var(--bs-white);
  border: 0;
  margin-top: 0;
  left: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}
.location-button svg, .back-to-home svg {
  width: 18px;
  height: 18px;
}

.back-to-home {
  display: flex;
}

.slider {
  width: 100%;
  margin: auto;
  position: fixed;
  height: 70%;
  z-index: 1001;
  left: 50%;
  bottom: 0;
  opacity: 0;
  background: var(--bs-white);
  transform: translate(-50%, 100%);
  transition: transform 0.2s linear, opacity 0.2s linear;
  will-change: auto;
}
.slider h3 {
  margin: 0;
}
@media (min-width: 601px) {
  .slider {
    height: 80%;
    max-height: 650px;
    max-width: 500px;
    border-radius: 10px;
  }
}

.active-slider {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  will-change: auto;
  bottom: 5px;
}
@media (max-width: 600px) {
  .active-slider {
    min-width: 100%;
    bottom: 0;
  }
}
.active-slider .sidebar-info svg {
  fill: var(--active-sidebar);
}
.active-slider .sidebar-info .menu-text {
  color: var(--active-sidebar);
}

.fullscreen-is-active .map-container {
  position: fixed;
  top: 0;
  height: 100%;
  min-width: 100%;
  z-index: 9;
}

.switch-container {
  display: flex;
  margin-left: auto;
}

.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}
.switch input {
  display: none;
}

.swich-slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.swich-slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .swich-slider {
  background-color: #66bb6a;
}

input:checked + .swich-slider:before {
  transform: translateX(26px);
}

.swich-slider.round {
  border-radius: 34px;
}
.swich-slider.round:before {
  border-radius: 50%;
}

[data-layer-id=show-all-layers] {
  background-color: var(--bs-gray-200);
  border-bottom: 1px solid var(--bs-gray-300);
}
[data-layer-id=show-all-layers] .information-row-name {
  font-weight: bold;
}

.color-menu, .fullscreen-is-active .sidebar-fullscreen-exit, .information-is-active .sidebar-info, .players-is-active .sidebar-search, .leaderboard-is-active .sidebar-leaderboard {
  color: var(--bs-yellow);
}
.color-menu svg, .fullscreen-is-active .sidebar-fullscreen-exit svg, .information-is-active .sidebar-info svg, .players-is-active .sidebar-search svg, .leaderboard-is-active .sidebar-leaderboard svg {
  fill: var(--bs-yellow);
}

.round-number {
  margin-left: 5px;
}

.projected-cut {
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 14px;
  background-color: var(--main-color-bg) !important;
  color: var(--bs-white);
}

.slider-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99999;
  max-width: 500px;
}

.small-font {
  font-size: 90%;
}

.auto-results-wrapper.auto-is-active {
  position: relative;
  margin-top: 10px;
}

.auto-results-wrapper {
  border: 0;
}

.loupe:before {
  top: 17px;
  bottom: auto;
}

.auto-clear {
  top: 12px;
  bottom: auto;
}

#auto-search-players-results {
  height: var(--slider-height);
}

.players-is-active .slider-content {
  overflow: hidden;
}

.auto-search-wrapper input {
  padding: 10px 12px;
}

.auto-head {
  top: 0;
  text-transform: uppercase;
  align-items: center;
  color: var(--bs-white);
  background-color: var(--main-color-bg);
  font-size: 90%;
}

.auto-pr {
  padding-right: var(--touch-screen-players) !important;
}

.auto-row .auto-group {
  order: 1;
}
.auto-row .auto-country {
  order: 3;
}
.auto-row .auto-player-name {
  order: 2;
}
.auto-row .auto-tee {
  order: 4;
}
.auto-row .auto-thru {
  order: 5;
}

.auto-search-wrapper input {
  margin: 10px auto 0 auto;
  width: calc(100% - 20px);
  display: flex;
}

.auto-clear {
  top: 11px;
  right: 8px;
}

.auto-search-wrapper ul li {
  padding: 0;
  font-size: 90%;
}

.auto-search-wrapper .border-top {
  border-color: var(--bs-gray-400) !important;
}

.boda-box {
  cursor: pointer;
  height: 100%;
  left: 0;
  border: 20px solid red;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  z-index: 999;
}

.accordion {
  width: 100%;
  background-color: var(--bs-white);
  border: none;
  outline: none;
  text-align: left;
  padding: 15px 10px;
  font-size: 18px;
  color: var(--bs-blue);
  border-bottom: 1px solid var(--bs-gray-500);
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.accordion:after {
  content: "↓";
  float: right;
}

.accordion.is-open:after {
  content: "↑";
}

.accordion:hover,
.accordion.is-open {
  background-color: var(--bs-gray-200);
}

.accordion-content {
  background-color: var(--bs-white);
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.accortion-list {
  margin-left: -13px;
}

.accortion-list svg {
  width: 16px;
  height: 16px;
  margin-bottom: 4px;
  fill: var(--bs-gray-700);
}

.popup-info {
  position: fixed;
  background: var(--bs-gray-100);
  color: var(--bs-gray-900);
  width: calc(100% - 20px);
  height: max-content;
  max-width: 500px;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  z-index: 1;
  margin: auto;
  opacity: 0;
  border-radius: 5px;
  padding: 10px;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

.active-popup .popup-info {
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
}

.popup-info .popup-header {
  font-weight: 600;
  margin-bottom: 10px;
}
.popup-info .popup-close {
  position: absolute;
  top: 4px;
  right: 5px;
  padding: 0;
  border: 0;
  background: transparent;
}
.popup-info .popup-close .icon {
  pointer-events: none;
  background: none;
  width: 30px;
  height: 30px;
  fill: var(--bs-gray-900);
}

.highlights-logo img {
  margin-top: 4px;
  max-height: 50px;
  border: 1px solid var(--bs-gray-300);
}

.highlights-row {
  font-size: 90%;
}

.highlights-row:nth-child(even) {
  background-color: var(--bs-gray-100);
}

.highlights-row:hover {
  cursor: pointer;
  background-color: var(--bs-gray-200);
}

.highlights-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-links-highlights {
  padding-bottom: 15px;
}
.social-links-highlights > div {
  margin-right: 20px;
}
.social-links-highlights svg {
  width: 24px;
  height: 24px;
  fill: #888;
  transition: fill 500ms linear;
}
.social-links-highlights svg:hover {
  fill: #3f3f3f;
}

.hole-info {
  color: #192267;
}
.hole-info-par strong {
  font-size: 0.9rem;
}
.hole-info-avg {
  color: var(--bs-gray-500);
}

.card-leaflet {
  box-shadow: none;
  width: 100%;
  max-width: 260px;
}
@media (min-width: 768px) {
  .card-leaflet {
    max-width: 300px;
  }
}
.card-leaflet .card-body {
  padding: 0;
}
.card-leaflet .card-body p {
  margin-top: 10px;
  font-size: 120%;
}
.card-leaflet .card-body a {
  color: #fff;
}
.card-leaflet .card-body video {
  max-width: 300px;
  width: 100%;
  max-height: 170px;
}
.card-leaflet .popup-content {
  font-size: 110%;
  border-top: 1px solid var(--bs-gray-300);
  padding-top: 10px;
}
.card-leaflet .popup-content h4 {
  text-transform: uppercase;
}
.card-leaflet .popup-content thead th {
  font-weight: bold;
}
.card-leaflet .popup-content .top-five-country span {
  width: 19px;
  height: 15px;
}

.card-twitter a {
  color: #1d9bf0 !important;
  text-decoration: none;
}
.card-twitter a:hover {
  text-decoration: underline;
}
.card-twitter .title {
  padding-top: 3px;
  margin-left: -10px;
}
.card-twitter .logo {
  border-radius: 50%;
}
.card-twitter .video {
  max-width: 300px;
  width: 100%;
}

.twitter-logo {
  margin: -15px 0 -10px;
}