.popup-content {
  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
  }

  tbody th {
    font-weight: normal;
  }

  .table > :not(caption) > * > * {
    padding: 0.1rem 0.3rem;
  }

  thead > tr > :nth-child(1),
  tbody > tr > :nth-child(1) {
    padding: 0.1rem 0rem;
  }

  .top-five-country span {
    width: 14px;
    height: 11px;
  }
}

.leaflet-popup {
  .card {
    border: 0;
  }
  .card-title {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .popup-content {
    h4 {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  table {
    margin-bottom: 0 !important;
  }

  .player-teetime {
    line-height: 120%;
  }
}

.group-popup {
  font-family: "Oswald", sans-serif;
  .leaflet-popup-content {
    min-width: 240px !important;
    max-width: 240px !important;
  }
}

.leaflet-popup:not(.group-popup) {
  font-family: "Roboto", sans-serif !important;
}
