// test
.slider {
  width: 100%;
  margin: auto;
  position: fixed;
  height: 70%;
  z-index: 1001;
  left: 50%;
  bottom: 0;
  opacity: 0;
  background: var(--bs-white);
  transform: translate(-50%, 100%);
  transition: transform 0.2s linear, opacity 0.2s linear;
  will-change: auto;

  h3 {
    margin: 0;
  }

  @media (min-width: 601px) {
    height: 80%;
    max-height: 650px;
    // position: absolute;
    max-width: 500px;
    border-radius: 10px;
  }
}

.active-slider {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  will-change: auto;
  bottom: 5px;
  @media (max-width: 600px) {
    min-width: 100%;
    bottom: 0;
  }

  .sidebar-info {
    svg {
      fill: var(--active-sidebar);
    }
    .menu-text {
      color: var(--active-sidebar);
    }
  }
}

.fullscreen-is-active {
  .map-container {
    position: fixed;
    top: 0;
    height: 100%;
    min-width: 100%;
    z-index: 9;
  }
}

.switch-container {
  display: flex;
  margin-left: auto;
}

.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;

  input {
    display: none;
  }
}

.swich-slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;

  &:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
  }
}

input:checked + .swich-slider {
  background-color: #66bb6a;
}

input:checked + .swich-slider:before {
  transform: translateX(26px);
}

.swich-slider.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

[data-layer-id="show-all-layers"] {
  background-color: var(--bs-gray-200);
  border-bottom: 1px solid var(--bs-gray-300);
  .information-row-name {
    font-weight: bold;
  }
}

// sidebar menu
.color-menu {
  color: var(--bs-yellow);
  svg {
    fill: var(--bs-yellow);
  }
}

.leaderboard-is-active {
  .sidebar-leaderboard {
    @extend .color-menu;
  }
}

.players-is-active {
  .sidebar-search {
    @extend .color-menu;
  }
}

.information-is-active {
  .sidebar-info {
    @extend .color-menu;
  }
}

.fullscreen-is-active {
  .sidebar-fullscreen-exit {
    @extend .color-menu;
  }
}

.round-number {
  margin-left: 5px;
}

.projected-cut {
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 14px;
  background-color: var(--main-color-bg) !important;
  color: var(--bs-white);
}

.slider-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99999;
  // overflow: hidden;
  max-width: 500px;
}

.small-font {
  font-size: 90%;
}
