.highlights-logo img {
  margin-top: 4px;
  max-height: 50px;
  border: 1px solid var(--bs-gray-300);
}

.highlights-row {
  font-size: 90%;
}

.highlights-row:nth-child(even) {
  background-color: var(--bs-gray-100);
}

.highlights-row:hover {
  cursor: pointer;
  background-color: var(--bs-gray-200);
}

.highlights-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-links-highlights {
  > div {
    margin-right: 20px;
  }
  padding-bottom: 15px;
  svg {
    width: 24px;
    height: 24px;
    fill: #888;
    transition: fill 500ms linear;
    &:hover {
      fill: #3f3f3f;
    }
  }
}
