.card-leaflet {
  box-shadow: none;
  width: 100%;
  max-width: 260px;
  @media (min-width: 768px) {
    max-width: 300px;
  }

  .card-body {
    padding: 0;

    p {
      margin-top: 10px;
      font-size: 120%;
    }

    a {
      color: #fff;
    }

    video {
      max-width: 300px;
      width: 100%;
      max-height: 170px;
    }
  }

  .popup-content {
    font-size: 110%;
    border-top: 1px solid var(--bs-gray-300);
    padding-top: 10px;
    h4 {
      text-transform: uppercase;
    }

    thead th {
      font-weight: bold;
    }

    .top-five-country span {
      width: 19px;
      height: 15px;
    }
  }
}

// twitter
.card-twitter {
  a {
    color: #1d9bf0 !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .title {
    padding-top: 3px;
    margin-left: -10px;
  }

  .logo {
    border-radius: 50%;
  }

  .video {
    max-width: 300px;
    width: 100%;
  }
}

// logo twitter remove margin top/bottom
.twitter-logo {
  margin: -15px 0 -10px;
}
